import { Box, Button, ControlList, Dialog, GenericSuspense, IconButton, MainLogo, Metadata, RightSidebar, useRuntimeEnv, useTranslationRoute, useTranslations, useUrlParamsValueToString } from "@mzara/component";
import { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useWorkHourControl } from "../views/list/hooks/useWorkHourControl";
import { useOrganisationWorkHourQuery } from "../views/list/hooks/useOrganisationWorkHourQuery";

export const CalendarPlanningContainer = () => {

    const [
        TITLE,
        ADD,
        WORK_HOUR_CARD_TITLE,
        TIMETABLE,
        PAUSE,
        WORK_HOUR_DIALOG_TITLE
    ] = useTranslations(i18n);


    const [wantToUpdateWorkHour, setWantToUpdateWorkHour] = useState(false);
    const tr = useTranslationRoute();
    const navigate = useNavigate();
    const urlParamsValue = useUrlParamsValueToString();
    const { runtimeEnv } = useRuntimeEnv()
    const workHourControl = useWorkHourControl()
    const { data: workHourData, invalidateQuery } = useOrganisationWorkHourQuery(runtimeEnv?.organisation?.id);

    return (
        <RightSidebar
            className={`right-sidebar-absolute overflow-x-hidden box box-white !z-50`}
        >
            <Box
                title={TITLE}
                icon="fa-solid fa-map"
                className="shadow-none p-0 flex flex-col gap-4"
                tools={
                    <IconButton
                        icon="fa-xmark"
                        onClick={() =>
                            navigate(
                                tr(
                                    `admin/project/calendar?${urlParamsValue}`
                                )
                            )
                        }
                    />

                }
            >
                <div className="w-full flex justify-end">
                    <Link
                        to={`-1?${urlParamsValue}`}
                    >
                        <Button
                            className="btn btn-primary"
                            label={ADD}
                        />
                    </Link>
                </div>
                <Box
                    title={WORK_HOUR_CARD_TITLE}
                    className={`px-5 !bg-default !text-gray ${workHourData?.isEditable ? "cursor-pointer" : ""}`}
                    onClick={
                        workHourData?.isEditable
                            ? () => setWantToUpdateWorkHour(true)
                            : undefined
                    }
                >
                    <Metadata
                        label={TIMETABLE}
                        value={`${workHourData?.workHourBegin} - ${workHourData?.workHourEnd}`}
                    />
                    <Metadata
                        label={PAUSE}
                        value={`${workHourData?.workRestHourBegin} - ${workHourData?.workRestHourEnd}`}
                    />
                </Box>

                <GenericSuspense fallback={<MainLogo />}>
                    <Outlet />
                </GenericSuspense>

                <Dialog
                    title={WORK_HOUR_DIALOG_TITLE}
                    open={wantToUpdateWorkHour}
                    onClose={() => setWantToUpdateWorkHour(false)}
                >
                    <ControlList
                        {...workHourControl}
                        defaultValue={{
                            id: workHourData?.id,
                        }}
                        onCancel={() => setWantToUpdateWorkHour(false)}
                        onSubmited={() => {
                            invalidateQuery();
                            setWantToUpdateWorkHour(false);
                        }}
                    />
                </Dialog>

            </Box>
        </RightSidebar>
    );
};


const i18n = [
    "Harea.Calendar.Planning",
    "Harea.Planning.Sidebar.Add.Button.Label",
    "Harea.Planning.Edit.WorkHour.Card.Title",
    "std_timetable",
    "std_pause",
    "Harea.Planning.Edit.WorkHour.Dialog.Title",
];
