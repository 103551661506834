import { GraphQLQueryClass } from "@mzara/component";
import { TUserOrganisation } from "@mzara/graphql";

export class OrganisationWorkHourQuery
    implements GraphQLQueryClass<TUserOrganisation, TResponse>
{
    queryKey: string = "App.Harea.Organisation.Work.Hour.Query";

    gql: string = `
        query OrganisationWorkHourQuery($id: Float) {
            userOrganisation(filter: { id: $id }) {
                id
                isEditable
                workHourBegin
                workHourEnd
                workRestHourBegin
                workRestHourEnd
            }
        }
    `;

    variables?: any;

    constructor(variables: any) {
        this.variables = variables;
    }

    mapFn(data: TResponse): TUserOrganisation {
        return data.userOrganisation;
    }
}

type TResponse = {
    userOrganisation: TUserOrganisation;
};
